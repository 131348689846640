<div
  class="container mt-0 mt-md-2 mb-md-5 container-padding-override request-table"
  *ngIf="viewState.requestList.length > 0"
>
  <div class="lock-request-container">
    <div class="header">
      <p class="body2--bold color-black">
        {{ trans('CATALOG_PAGE.LOCKED_REQUEST_LIST.LOCKED_REQUEST_TITLE') }}
      </p>
      <p class="caption1--regular color-medium-gray">
        {{ trans('CATALOG_PAGE.LOCKED_REQUEST_LIST.LOCKED_REQUEST_DESC') }}
      </p>
    </div>
    <div class="items-container">
      <div class="product-list-body" *ngFor="let request of viewState.requestList">
        <div class="request">
          <div
            class="request-info"
            (click)="
              presenter.emitViewEvent({
                type: 'ClickProductInfo',
                productId: request.productId
              })
            "
          >
            <img [src]="request.productImage" />
            <div>
              <p class="body2--bold color-black">
                {{
                  isLTR ? request.productName.en || request.productName.ar : request.productName.ar
                }}
              </p>
              <p class="caption1--regular color-medium-gray">
                {{ trans('CATALOG_PAGE.LOCKED_REQUEST_LIST.LOCKED_REQUEST_QUANTITY') }}
                <b> {{ request.expectedDailyOrders }}</b>
              </p>
            </div>
          </div>
          <div class="request-status">
            <button
              class="status-pill caption1--regular"
              [ngClass]="{
                'pending-status': request.status === 'PENDING',
                'rejected-status': request.status === 'REJECTED'
              }"
            >
              {{
                request.status === 'PENDING'
                  ? trans('CATALOG_PAGE.LOCKED_REQUEST_LIST.PENDING_REVIEW_STATUS')
                  : trans('CATALOG_PAGE.LOCKED_REQUEST_LIST.REJECTED_STATUS')
              }}
            </button>
            <p class="caption2--regular color-medium-gray">
              {{ trans('CATALOG_PAGE.LOCKED_REQUEST_LIST.SENT_ON') }}:
              {{ request.createdAt | date : 'dd MMM yyyy' }}
            </p>
          </div>
        </div>
        <div class="rejection-reason" *ngIf="request.status === 'REJECTED'">
          <i class="icon icon-info"></i>
          <p class="caption1--regular">
            {{ request.rejectionReason | rejectionReason }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
