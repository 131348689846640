import { inject, Injectable } from '@angular/core';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { PreOrderBottomBannerSideEffect } from './preorder-bottom-banner.sideEffects';
import { PreOrderBottomBannerViewEvents } from './preorder-bottom-banner.viewEvents';
import { PreOrderBottomBannerViewState } from './preorder-bottom-banner.viewState';

@Injectable()
export class PreOrderBottomBannerPresenter extends BasePresenter<
  PreOrderBottomBannerViewState,
  PreOrderBottomBannerViewEvents,
  PreOrderBottomBannerSideEffect
> {
  private _logMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  protected defaultViewState(): PreOrderBottomBannerViewState {
    return {};
  }

  protected onViewEvent(event: PreOrderBottomBannerViewEvents): void {
    switch (event.type) {
      case 'ClickNavigateToAllProducts': {
        this._logMixpanelEventUseCase.execute({
          eventName: 'new_pre_order_bottom_banner_button_clicked',
        });
        this.emitSideEffect({
          type: 'NavigateToAllProducts',
        });
        break;
      }
    }
  }
}
