import { Injectable } from '@angular/core';
import { BasePresenter } from 'app/presentation/base/base.presenter';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { ProductLockRequestSideEffect } from './product-lock-request.side-effect';
import { ProductLockRequestViewEvent } from './product-lock-request.view-event';
import { ProductLockRequestViewState } from './product-lock-request.view-state';
/* eslint-disable */
import { mixPanelEvent } from '@/v3/features/activities/data';
import { lockingRequestTrackEvents } from '@/v3/features/product-lock-request/data/constants';
import { catchError } from '@/v3/presentation/shared/error/index';
import { ProductLockRequestUseCaseResolver } from '@/v3/features/product-lock-request/domain/resolvers/product-lock-request-use-case.resolver';
import { navigateTo } from '@/v3/presentation/shared/router';
import { PRODUCT_DETAILS } from 'app/presentation/shared/constants/app-urls';
/* eslint-enable */

@Injectable({ providedIn: 'root' })
export class ProductLockRequestPresenter extends BasePresenter<
  ProductLockRequestViewState,
  ProductLockRequestViewEvent,
  ProductLockRequestSideEffect
> {
  constructor(private _logMixpanelEventUseCase: LogMixpanelEventUseCase) {
    super();
  }

  protected defaultViewState(): ProductLockRequestViewState {
    return {
      requestList: [],
    };
  }

  onViewEvent(event: ProductLockRequestViewEvent): void {
    switch (event.type) {
      case 'Init': {
        mixPanelEvent(lockingRequestTrackEvents.TRACK_REQUEST);
        this.fetchProductRequestList();
        break;
      }
      case 'ClickProductInfo': {
        this.redirectToProduct(event.productId);
        break;
      }
    }
  }

  public fetchProductRequestList(): void {
    ProductLockRequestUseCaseResolver.productLockRequestsList()
      .then((result) => {
        result = result.filter((product) => product.status !== 'ACCEPTED');
        this.viewState.requestList = result;
      })
      .catch((error) => {
        catchError(error);
      });
  }

  public redirectToProduct(productId: string): void {
    navigateTo(`${PRODUCT_DETAILS}/${productId}`);
  }
}
