import { Component, inject, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { MultitenancyService } from 'src/app/presentation/shared/services/multitenancy.service';
import { CurrencyShortNamePipe } from '../../../shared/pipes/currency-short-name.pipe';
import { CurrencyTranslatePipe } from '../../../shared/pipes/currency-translate.pipe';

@Component({
  selector: 'app-mini-product-card',
  templateUrl: './mini-product-card.component.html',
  styleUrls: ['./mini-product-card.component.scss'],
  standalone: true,
  imports: [RouterLink, TranslateModule, CurrencyShortNamePipe, CurrencyTranslatePipe],
})
export class MiniProductCardComponent implements OnInit {
  @Input() product: any;

  currency: string;

  private _logMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  constructor(private multitenancyService: MultitenancyService) {}

  ngOnInit(): void {
    this.currency = this.multitenancyService.getCurrentCountry().currency.arabicName;
  }

  goToProduct() {
    this._logMixpanelEventUseCase.execute({
      eventName: 'Go_to_product',
      payload: {
        'Product Name': this.product.productName,
        'Product ID': this.product.prodID,
        'Product Availability': this.product.productAvailability,
        'Product Price': this.product.productPrice,
        'Product Profit': this.product.productProfit,
        'Interface Version': 'v2',
      },
    });
  }
}
