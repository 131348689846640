import { CommonModule, NgFor } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { BaseComponent } from 'app/presentation/base/base.component';
import { RejectionReasonPipe } from '../../../shared/pipes/rejection-reason.pipe';
import { ProductLockRequestPresenter } from './presenter/product-lock-request.presenter';
import { ProductLockRequestSideEffect } from './presenter/product-lock-request.side-effect';
import { ProductLockRequestViewEvent } from './presenter/product-lock-request.view-event';
import { ProductLockRequestViewState } from './presenter/product-lock-request.view-state';

@Component({
  selector: 'app-product-lock-request-status',
  standalone: true,
  imports: [CommonModule, NgFor, RejectionReasonPipe],
  templateUrl: './product-lock-request-status.component.html',
  styleUrls: ['./product-lock-request-status.component.scss'],
  providers: [ProductLockRequestPresenter],
})
export class ProductLockRequestStatusComponent
  extends BaseComponent<
    ProductLockRequestPresenter,
    ProductLockRequestViewState,
    ProductLockRequestViewEvent,
    ProductLockRequestSideEffect
  >
  implements OnInit
{
  public presenter: ProductLockRequestPresenter = inject(ProductLockRequestPresenter);

  ngOnInit(): void {
    super.ngOnInit();
    this.presenter.onViewEvent({ type: 'Init' });
  }
}
