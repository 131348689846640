import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { BaseComponent } from 'src/app/presentation/base/base.component';
import { PreOrderBottomBannerPresenter } from './presenter/preorder-bottom-banner.presenter';
import { PreOrderBottomBannerSideEffect } from './presenter/preorder-bottom-banner.sideEffects';
import { PreOrderBottomBannerViewEvents } from './presenter/preorder-bottom-banner.viewEvents';
import { PreOrderBottomBannerViewState } from './presenter/preorder-bottom-banner.viewState';

@Component({
  selector: 'app-preorder-bottom-banner',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './preorder-bottom-banner.component.html',
  styleUrls: ['./preorder-bottom-banner.component.scss'],
  providers: [PreOrderBottomBannerPresenter],
})
export class PreorderBottomBannerComponent extends BaseComponent<
  PreOrderBottomBannerPresenter,
  PreOrderBottomBannerViewState,
  PreOrderBottomBannerViewEvents,
  PreOrderBottomBannerSideEffect
> {
  public assetsPath = 'assets/img/pre-order-banner/';

  public presenter: PreOrderBottomBannerPresenter = inject(PreOrderBottomBannerPresenter);

  private _router: Router = inject(Router);

  private readonly _appURLs: { [url: string]: string } = inject(appUrlsConstantsInjectionToken);

  onSideEffect(sideEffect: PreOrderBottomBannerSideEffect): void {
    switch (sideEffect.type) {
      case 'NavigateToAllProducts': {
        this._router.navigate([this._appURLs.PRODUCTS_V2_URL]);
        break;
      }
    }
  }
}
