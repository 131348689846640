<div class="bottom-banner">
  <p class="heading1--bold white-text">{{ 'PRE_ORDER_PAGE.BOTTOM_BANNER.BROWSE' | translate }}</p>
  <p class="heading1--bold white-text">{{ 'PRE_ORDER_PAGE.BOTTOM_BANNER.QUANTITY' | translate }}</p>
  <p class="body1--regular white-text">{{ 'PRE_ORDER_PAGE.BOTTOM_BANNER.PRODUCTS' | translate }}</p>
  <div class="bottom-banner__card">
    <div class="bottom-banner__card__right-part">
      <img src="{{ assetsPath + 'box.svg' }}" />
      <div>
        <p class="body1--bold white-text">
          {{ 'PRE_ORDER_PAGE.BOTTOM_BANNER.RESERVE' | translate }}
        </p>
        <p class="caption1--regular white-text">
          {{ 'PRE_ORDER_PAGE.BOTTOM_BANNER.LOCK' | translate }}
        </p>
      </div>
    </div>
    <button
      class="bottom-banner__card__button"
      (click)="presenter.emitViewEvent({
      type: 'ClickNavigateToAllProducts',
    })"
    >
      {{ 'PRE_ORDER_PAGE.BOTTOM_BANNER.BROWSE_PRODUCTS' | translate }}
    </button>
  </div>
</div>
